.signup-complete {
    height: 100%;
    background-color: #9746B7;
    display: flex;
    align-items: center;
    justify-content: center;
}

.signup-complete-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 20px;
    text-align: center;
    border-radius: 10px;
    width: 30vw;
    min-width: 350px;
}

.signup-complete h2 {
    font-family: "museo-slab", serif;
    font-weight: 900;
    font-size: 30px;
}

.signup-complete p {
    font-family: "museo-sans", sans-serif;
    font-weight: 500;
    font-size: 16px;
}

.complete-loading-icon {
    margin-top: 20px;
    height: 150px;
    animation-name: hourglass;
    animation-duration: 4s;
    animation-iteration-count: infinite;
}

@keyframes hourglass {
    0% { transform: rotate(0deg) }
    50% { transform: rotate(360deg) }
    100% { transform: rotate(360deg) }
}
